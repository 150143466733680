exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-pf-about-js": () => import("./../../../src/pages/pf-about.js" /* webpackChunkName: "component---src-pages-pf-about-js" */),
  "component---src-pages-pf-contact-js": () => import("./../../../src/pages/pf-contact.js" /* webpackChunkName: "component---src-pages-pf-contact-js" */),
  "component---src-pages-pf-cookie-policy-eu-js": () => import("./../../../src/pages/pf-cookie-policy-eu.js" /* webpackChunkName: "component---src-pages-pf-cookie-policy-eu-js" */),
  "component---src-pages-pf-cookie-policy-uk-js": () => import("./../../../src/pages/pf-cookie-policy-uk.js" /* webpackChunkName: "component---src-pages-pf-cookie-policy-uk-js" */),
  "component---src-pages-pf-news-js": () => import("./../../../src/pages/pf-news.js" /* webpackChunkName: "component---src-pages-pf-news-js" */),
  "component---src-pages-pf-preview-js": () => import("./../../../src/pages/pf-preview.js" /* webpackChunkName: "component---src-pages-pf-preview-js" */),
  "component---src-pages-pf-sitemap-js": () => import("./../../../src/pages/pf-sitemap.js" /* webpackChunkName: "component---src-pages-pf-sitemap-js" */),
  "component---src-pages-pf-work-js": () => import("./../../../src/pages/pf-work.js" /* webpackChunkName: "component---src-pages-pf-work-js" */),
  "component---src-templates-new-page-template-js": () => import("./../../../src/templates/new-page-template.js" /* webpackChunkName: "component---src-templates-new-page-template-js" */),
  "component---src-templates-news-blog-post-js": () => import("./../../../src/templates/news/blog-post.js" /* webpackChunkName: "component---src-templates-news-blog-post-js" */),
  "component---src-templates-people-single-people-js": () => import("./../../../src/templates/people/single-people.js" /* webpackChunkName: "component---src-templates-people-single-people-js" */),
  "component---src-templates-work-expertise-single-expertise-js": () => import("./../../../src/templates/work/expertise/single-expertise.js" /* webpackChunkName: "component---src-templates-work-expertise-single-expertise-js" */),
  "component---src-templates-work-single-work-js": () => import("./../../../src/templates/work/single-work.js" /* webpackChunkName: "component---src-templates-work-single-work-js" */)
}

