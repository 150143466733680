export const baseUrl = 'https://pearlfisher.wpenginepowered.com/graphql';

export const locationAPI = 'https://geolocation-db.com/json/62d8d690-e7b8-11ed-8c81-cbb990727ab5';
export const locationAPITest = 'https://api.ipgeolocation.io/ipgeo?apiKey=dad9781e3c0f4ab5ba04c7caa12a11c6';

export const defaultCountry = 'UK';
export const defaultLanguage = 'en-gb';

export const unitedKingdomCountryCode= 'UK';
export const unitedStateCountryCode= 'US';
export const chainaCountryCode= 'CN';

export const unitedKingdomLanguage = 'en-gb';
export const unitedStateLanguage = 'en';
export const chainaLanguage = 'zh-hans';

export const unitedKingdomLanguageURIPath = 'uk';
export const unitedStateLanguageURIPath = 'us';
export const chainaLanguageURIPath = 'cn';

export const graphqlURL = "https://pearlfisher.wpenginepowered.com/graphql";
export const API_URL = "https://pearlfisher.wpenginepowered.com";

export const wpURL = 'https://pearlfisher.wpenginepowered.com/';
export const hostURL = 'https://pearlfisher.com/';
export const hostURLNoSlash = 'https://pearlfisher.com/';
export const hostBaseURL = 'pearlfisher.com';