import * as Global from "../lib/global"

const isBrowser = typeof window !== "undefined"


const GetLanguageCodeNew = async () => {

	let currentURI = {};
	let previousData = {}
	let currentURL;
	let currentOrigin;
	let pathName;
	let callLocalStorage;
	let api_status;
	let redirection_status;
	//let previousURL;
	let previousCountryCode;
	let callSessionStorage;

	let currentTimeStamp = Math.floor(Date.now() / 1000)

	if (isBrowser) {
		callLocalStorage = typeof localStorage !== 'undefined' && localStorage;
		callSessionStorage = typeof sessionStorage !== 'undefined' && sessionStorage;

		if (callSessionStorage === undefined || callSessionStorage.length === 0) {
			callLocalStorage.removeItem("userBrowserInfo");
		}


		currentURI = window.location.pathname.split('/');
		pathName = window.location.pathname
		previousData = JSON.parse(callLocalStorage.getItem('userBrowserInfo'));
		currentURL = window.location.href
		currentOrigin = window.location.origin
		//previousURL = document.referrer;
	}

	const response = {};

	let languageURI = currentURI[1] ? currentURI[1] : '';


	const isSSR = localStorage.getItem('ssr');

	if (isSSR === 'true') {
	} else {
		// Set the flag in local storage
		localStorage.setItem('ssr', 'true');
	}

	/* User suffer first time in browser  */

	if (previousData === null) {
		response.language_code = Global.defaultLanguage;
		response.current_url = currentURL;
		response.expired_at = currentTimeStamp - 300;
		response.redirection = false;
		response.api_status = true;
		if (isBrowser) {
			callLocalStorage.setItem("userBrowserInfo", JSON.stringify(response));
		}

	}

	let expired_at_time = (previousData) ? previousData.expired_at ? previousData.expired_at : currentTimeStamp : currentTimeStamp;

	api_status = (previousData) ? previousData.api_status ? previousData.api_status : false : false;

	redirection_status = (previousData) ? previousData.redirection ? previousData.redirection : false : false;

	previousCountryCode = (previousData) ? previousData.country_code ? previousData.country_code : '' : '';


	if (true) {

		if (redirection_status === false) {
			api_status = true;
		} else if (redirection_status === false && pathName === '/' && languageURI==='') {
			api_status = false;
		}

		if (expired_at_time < currentTimeStamp) {
			api_status = false;
		}


		if (api_status === true && languageURI==='') 
		{
			if (isBrowser) {
				response.api_status = true;
				callLocalStorage.setItem("userBrowserInfo", JSON.stringify(response));
			}

			//await fetch(Global.locationAPI)
			await fetch(Global.locationAPITest)
				.then(responseData => responseData.json())
				.then(responseData => {
					var lastChar = pathName.substr(-1);
					if (lastChar !== '/') {
						pathName = pathName + '/';
					}
					if (responseData.country_code2 === Global.chainaCountryCode) {
						var urlSlugCN = '';

						if (typeof (currentURI[1]) !== "undefined" && (currentURI[1] === "us" || currentURI[1] === "cn")) {
							urlSlugCN = pathName.slice(pathName.indexOf("/", 1));
						} else {
							urlSlugCN = pathName;
						}
						response.language_code = Global.chainaLanguage;
						response.current_url = currentOrigin + '/cn' + urlSlugCN;
						if (isBrowser) {
							callLocalStorage.setItem("userBrowserInfo", JSON.stringify(response));
							if(languageURI !=='us' || languageURI !=='cn')
							{
								window.location.replace(response.current_url);
								localStorage.setItem('ssr', 'true');
							}
							
						}
					} else if (responseData.country_code2 === Global.unitedStateCountryCode) {
						var urlSlugUS = '';

						if (typeof (currentURI[1]) !== "undefined" && (currentURI[1] === "us" || currentURI[1] === "cn")) {
							urlSlugUS = pathName.slice(pathName.indexOf("/", 1));
						} else {
							urlSlugUS = pathName;
						}
						response.language_code = Global.unitedStateLanguage;
						response.current_url = currentOrigin + '/us' + urlSlugUS;
						if (isBrowser) {
							callLocalStorage.setItem("userBrowserInfo", JSON.stringify(response));
							if(languageURI !=='us' || languageURI !=='cn')
							{
								window.location.replace(response.current_url);
								localStorage.setItem('ssr', 'true');
							}
						}
					} else {
						response.language_code = Global.defaultLanguage;
						response.current_url = currentOrigin + pathName;
					}

					response.country_code = responseData.country_code2;
					response.redirection = true;
					response.api_status = true;
					response.expired_at = currentTimeStamp + 300;
					response.in_country = responseData.country_code2;

					if (isBrowser) {
						callSessionStorage.setItem("in_country", responseData.country_code2);
					}

					if (languageURI !== '') {

						if (languageURI === Global.unitedStateLanguageURIPath) {
							response.language_code = Global.unitedStateLanguage;
						} else if (languageURI === Global.chainaLanguageURIPath) {
							response.language_code = Global.chainaLanguage;
						} else {
							response.language_code = Global.defaultLanguage;
						}
					} else {
						response.language_code = Global.defaultLanguage;
					}

				}).catch(err => console.error(err));

		} else {
			response.current_url = currentURL;
			response.api_status = true;
			response.redirection = true;

			if (languageURI !== '') {

				if (languageURI === Global.unitedStateLanguageURIPath) {
					response.language_code = Global.unitedStateLanguage;
					response.country_code = Global.unitedStateCountryCode;
				} else if (languageURI === Global.chainaLanguageURIPath) {
					response.language_code = Global.chainaLanguage;
					response.country_code = Global.chainaCountryCode;
				} else {
					response.language_code = Global.defaultLanguage;
					response.country_code = Global.unitedKingdomCountryCode;
				}
			} else {
				response.language_code = Global.defaultLanguage;
				response.country_code = Global.unitedKingdomCountryCode;
			}

			response.in_country = previousCountryCode;

			response.expired_at = currentTimeStamp + 1;

			if (isBrowser) {
				callLocalStorage.setItem("userBrowserInfo", JSON.stringify(response));
			}
		}
	} else {
		response.current_url = currentURL;
		response.api_status = false;

		response.redirection = true;

		if (languageURI !== '') {

			if (languageURI === Global.unitedStateLanguageURIPath) {
				response.language_code = Global.unitedStateLanguage;
			} else if (languageURI === Global.chainaLanguageURIPath) {
				response.language_code = Global.chainaLanguage;
			} else {
				response.language_code = Global.defaultLanguage;
			}
		} else {
			response.language_code = Global.defaultLanguage;
		}

		response.expired_at = currentTimeStamp + 1;
		response.previous_country_code = previousCountryCode;

		if (isBrowser) {
			callLocalStorage.setItem("userBrowserInfo", JSON.stringify(response));
		}

	}

}

export default GetLanguageCodeNew