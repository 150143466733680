// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
import "./src/assets/css/custom1.css"
import "./src/assets/css/aos.css"
import "./src/assets/css/cookie-policy1.css"
import "bootstrap/dist/js/bootstrap.bundle.js"
import "jquery/dist/jquery.min.js"
import getLanguageCodeGlobally from "./src/components/LanguageCodeNew";

const isBrowser = typeof window !== "undefined"


export const onClientEntry = () => {
    

    const isloader = localStorage.getItem('loader');

    getLanguageCodeGlobally()
    //PageRedirect()

    if (isloader === 'true') {
        localStorage.setItem('loader', 'false');
    }
}


export const onRouteUpdate = ({ location, prevLocation }) => {
    getLanguageCodeGlobally()
    if (!prevLocation && !location.hash) {
        // do not restore scroll position on page refresh without hash
        setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: 'instant' }), 0)
    }
}

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
    window.history.scrollRestoration = 'manual'
    const currentPosition = getSavedScrollPosition(location) || [0, 0]

    setTimeout(() => {
        if (location.hash) {
            // If hash (`/#something`), smooth scroll to that position
            const item = document.querySelector(`${location.hash}`)
            item?.scrollIntoView({ behavior: 'smooth' })
        } else {
            // when using the browser back/forward buttons or navigating to a different page client-side, instant scroll to its last saved position
            window.scrollTo({
                top: currentPosition[1],
                left: currentPosition[0],
                behavior: 'instant',
            })
        }
    }, 0)

    // do not let gatsby do anything more
    return false
}